import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './components/index';
document.addEventListener('DOMContentLoaded', function () {

    try {
        let app = firebase.app();
        const root = document.getElementById('root')
        ReactDOM.render(<App app={app} />, root)
    } catch (e) {
        const root = document.getElementById('root')
        ReactDOM.render(<App />, root)
        console.error(e);
        // document.getElementById('load').innerHTML = 'Error loading the Firebase SDK, check the console.';
    }

});